import React from 'react'

const Portfolio = () => {
    return (
        <div>
            This is the Portfolio Page
        </div>
    )
}

export default Portfolio
